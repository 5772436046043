<template>
  <div>
    <Heard />
    <div class="banner container-fluid">
      <img class="container-fluid" src="@/assets/image/stuindex.jpg" alt="" />
    </div>

    <div class="list2cont container">
      <img class="container" src="@/assets/image/stucont.jpg" alt="" />
    </div>

    <Footer />
  </div>
</template>

<script >
import Heard from "@/components/Heard.vue";
// import Newlist from "@/components/Newlist.vue";
import Footer from "@/components/Footer.vue";
// import { api_queryNewsTitleList } from "@/apis/apis.js";
export default {
  components: {
    Heard,
    Footer,
  },

  data() {
    return {
      totalCount: 1,
      currPage: 1,
      list: [],
      rightlist: [],
    };
  },
  // created() {
  //   api_queryNewsList({}).then((res) => {
  //     console.log(res);
  //   });
  // },

  methods: {},
};
</script>

<style lang="less" scoped>
.container-fluid {
  min-width: 1200px;
  /* 版心 */
}
.container {
  width:90%;
  margin: 0 auto;
}
.banner {
  display: flex;
  justify-content: center;
  // background: url(~@/assets/image/stuindex.png) no-repeat center;
  // background-size: 100% 100%;
  img {
    max-height: 400px;
    -width: 1920px;
  }
}
.list2cont {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  img {
    // max-width: 1130px;
    // height: 890px;
  }
}
</style>